<template>
  <b-row>
    <b-col>
      <div class="center-content text-center">
        <h1 class="text-60">
          503
        </h1>
        <p class="text-36 subheading mb-3">Servicio No Disponible!</p>
        <p class="text-muted text-18">
          ¡Lo sentimos! Estamos realizando mejoras en el sitio.
        </p>
        <p class="mb-5 text-muted text-18">
          Intenta de nuevo mas tarde.
        </p>
        <button class="btn btn-lg btn-skyblue btn-rounded" @click="ping"
        >Intentar de nuevo</button
        >
      </div>
    </b-col>
  </b-row>
</template>
<script>
import axios from '@/modules/common/axiosERP';

export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: '503 - Servicio No Disponible!',
  },
  methods: {
    ping() {
      axios.get('/pais/all')
        .then(() => {
          window.location = '/';
        });
    },
  },
};
</script>
<style scoped>
.btn-skyblue
{
  color: #fff;
  background-color: #61c6f2;
}
.btn-skyblue:hover
{
  box-shadow: 0px 5px 20px #61c6f2;
}
.center-content{
  height: 100vh;
  padding: 120px 0;
}
.center-content h1{
  font-weight: 800;
  margin-bottom: 16px;
  line-height: 1;
}
.center-content .subheading{
  font-weight: 800;
}
</style>
